// Generated by Framer (2e3161d)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, ResolveLinks, useComponentViewport, useLocaleInfo, useRouter, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { BreadcrumbList } from "../codeFile/BreadcrumbList.tsx";
const BreadcrumbListFonts = getFonts(BreadcrumbList);

const serializationHash = "framer-DCOOQ"

const variantClassNames = {yUQtoVUMl: "framer-v-1udqegc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, EYvFghbZ1aIPCG5kdz, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "yUQtoVUMl", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const router = useRouter()

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1udqegc", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"yUQtoVUMl"} ref={ref ?? ref1} style={{...style}}><ResolveLinks links={[{href: {webPageId: "SXO9iiSq5"}, implicitPathVariables: undefined}, {href: {pathVariables: {EYvFghbZ1: EYvFghbZ1aIPCG5kdz}, webPageId: "aIPCG5kdz"}, implicitPathVariables: undefined}]}>{(resolvedLinks) => (
<ComponentViewportProvider ><motion.div className={"framer-tk69d9-container"} layoutDependency={layoutDependency} layoutId={"qA60qtX0g-container"}><BreadcrumbList height={"100%"} id={"qA60qtX0g"} layoutId={"qA60qtX0g"} LinkComponent={[]} links={[{href: "/", text: "Home"}, {href: resolvedLinks[0], text: "Blog"}, {href: resolvedLinks[1], text: "Shoes"}]} SeparatorComponent={[]} style={{width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider>
)}</ResolveLinks></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DCOOQ.framer-1m5c201, .framer-DCOOQ .framer-1m5c201 { display: block; }", ".framer-DCOOQ.framer-1udqegc { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 625px; }", ".framer-DCOOQ .framer-tk69d9-container { flex: 1 0 0px; height: auto; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-DCOOQ.framer-1udqegc { gap: 0px; } .framer-DCOOQ.framer-1udqegc > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-DCOOQ.framer-1udqegc > :first-child { margin-left: 0px; } .framer-DCOOQ.framer-1udqegc > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 0
 * @framerIntrinsicWidth 625
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramernCSP4YG7O: React.ComponentType<Props> = withCSS(Component, css, "framer-DCOOQ") as typeof Component;
export default FramernCSP4YG7O;

FramernCSP4YG7O.displayName = "Test";

FramernCSP4YG7O.defaultProps = {height: 0, width: 625};

addFonts(FramernCSP4YG7O, [{explicitInter: true, fonts: []}, ...BreadcrumbListFonts], {supportsExplicitInterCodegen: true})